<template>
  <div class="full-shop">
    <div
      v-if="item"
      class="full-shop-banner"
      :class="{
        'bg-green': showDaily,
        'bg-orange': showExpress,
        'bg-cyan': showSuper,
        'bg-red': showMagnum,
      }"
    >
      <div class="container">
        <div class="full-shop-banner__top">
          <div class="full-shop-banner__logo-wrapper">
            <img
              v-if="showMagnum"
              src="@/assets/img/shop/magnum.svg"
              alt="logo"
              class="full-shop__logo"
            />
            <img
              v-if="showDaily"
              src="@/assets/img/shop/magnum-daily.svg"
              alt="logo"
              class="full-shop__logo"
            />
            <img
              v-if="showSuper"
              src="@/assets/img/shop/magnum-super.svg"
              alt="logo"
              class="full-shop__logo"
            />
            <img
              v-if="showExpress"
              src="@/assets/img/shop/magnum-express.svg"
              alt="logo"
              class="full-shop__logo"
            />
          </div>
          <div class="full-shop-banner__address">
            <div
              v-if="item.attributes.name"
              class="full-shop-banner__address-name"
            >
              {{ item.attributes.name }}
            </div>
            <div
              v-if="item.attributes.address"
              class="full-shop-banner__address-location"
            >
              {{ item.attributes.address }}
            </div>
            <div class="full-shop-banner__address-time">
              {{ $t("workingMode") }}
              {{
                getShopWorkTime(item) === "aroundClock"
                  ? $t(getShopWorkTime(item))
                  : getShopWorkTime(item)
              }}
            </div>
          </div>
        </div>

        <div v-if="range.length" class="full-shop-banner__bottom">
          <div class="full-shop-banner__services">
            <div class="full-shop-banner__services-title">
              {{ $t("expandedRange") }}
            </div>
            <div class="full-shop-banner__services-items">
              <div
                v-for="(item, index) in range"
                :key="index"
                class="full-shop-banner__services-item"
              >
                <img
                  class="full-shop-banner__services-img"
                  :src="getImage(item)"
                  alt=""
                />
                {{ item.attributes.name }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="services.length" class="full-shop-banner__bottom">
          <div class="full-shop-banner__services">
            <div class="full-shop-banner__services-title">
              {{ $t("additionalServices") }}
            </div>
            <div class="full-shop-banner__services-items">
              <div
                v-for="(item, index) in services"
                :key="index"
                class="full-shop-banner__services-item"
              >
                <img
                  class="full-shop-banner__services-img"
                  :src="getImage(item)"
                  alt=""
                />
                {{ item.attributes.name }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <img
        src="@/assets/img/shop/express.png"
        alt="product image"
        class="full-shop-banner__bg"
      />
    </div>
    <div v-else class="loader__container">
      <div class="loader"></div>
    </div>
    <div v-if="products" class="container">
      <div v-if="products.length" class="stocks-products__items">
        <div class="header-block">
          <h2 class="title">{{ $t("discountsValid") }}</h2>
        </div>
        <div class="page-catalog__products full-shop__products">
          <div class="page-catalog__sorting full-shop__sorting">
            <div class="page-catalog__found full-shop__found">
              <span>{{ $t("found") }}</span>
              <span
                v-if="countProducts || countProducts === 0"
                class="number-found-product"
                >{{ countProducts }}</span
              >
              <span> {{ $t("goods") }}</span>
            </div>

            <div
              class="page-catalog__sorting-btn full-shop__sorting-btn"
              @click="isShowSort = !isShowSort"
            >
              {{ $t("sorting") }}
            </div>
            <discount-sort
              v-if="isShowSort"
              @sort-btn="isShowSort = false"
              @sort-obj="getSortObj"
            />
          </div>
        </div>
        <products-list v-if="products" :items="products" />
        <button
          v-if="!pagination.isLastPage"
          class="stocks-product-more"
          @click="nextPage"
          :disabled="isLoading"
        >
          {{ $t("seeAll") }}
        </button>
      </div>
    </div>

    <div class="container">
      <h3 class="title title__map">{{ $t("storeLocation") }}</h3>
    </div>
    <div v-if="item" class="full-shop__map">
      <yandex-map :coords="initCoord" :zoom="17" :controls="[]">
        <ymap-marker
          :coords="initCoord"
          marker-id="1"
          :icon="getMarkerIcon(item)"
        />
      </yandex-map>
    </div>
  </div>
</template>

<script>
import { getShop } from "@/api/shop";
import { getShopProducts } from "@/api/products";
import { loadYmap } from "vue-yandex-maps";
const MAP_MARKER_HYPER = require("@/assets/icons/hyper.svg");
import { yandexMap, ymapMarker } from "vue-yandex-maps";
export default {
  name: "FullShop",
  components: {
    yandexMap,
    ymapMarker,
    ProductsList: () => import("@/components/products/ProductsList.vue"),
    DiscountSort: () => import("@/components/discount/DiscountSort.vue"),
  },
  data() {
    return {
      services: [],
      range: [],
      allProducts: null,
      products: null,
      item: null,
      sort: null,
      initCoord: null,
      showSuper: false,
      showExpress: false,
      showDaily: false,
      showMagnum: false,
      isLoading: false,
      isShowSort: false,
      pagination: {
        page: 1,
        pageSize: 15,
        isLastPage: false,
      },
    };
  },
  async mounted() {
    await loadYmap({ debug: true });
  },
  computed: {
    countProducts() {
      return this.allProducts?.length;
    },
  },
  watch: {
    item: {
      deep: true,
      handler() {},
    },
    sort: {
      deep: true,
      handler() {
        getShopProducts(this.$route.params.id, this.pagination, this.sort).then(
          (response) => {
            this.products = response.data;
            if (response.meta?.pagination?.pageCount === this.pagination.page) {
              this.pagination.isLastPage = true;
            }
          }
        );
      },
    },
  },
  created() {
    getShop(this.$route.params.id)
      .then((shop) => {
        this.item = shop;

        this.range = [];
        this.services = [];
        this.item.attributes.shop_features?.data?.forEach((b) => {
          let f = null;
          if (this.$i18n.locale === "kz") {
            f = b?.attributes?.localizations?.data[0];
            // eslint-disable-next-line prettier/prettier
            f.attributes["logo"] = b?.attributes?.logo;
          }
          if (b.attributes.type_of_feature === "product_catalog") {
            this.range.push(f || b);
          } else {
            this.services.push(f || b);
          }
        });

        this.initCoord = [
          this.item.attributes.geo.latitude,
          this.item.attributes.geo.longitude,
        ];

        switch (this.item?.attributes?.shop_type?.data?.attributes?.name) {
          case "Daily":
            this.showDaily = true;
            break;
          case "Express":
            this.showExpress = true;
            break;
          case "Super":
            this.showSuper = true;
            break;
          default:
            this.showMagnum = true;
            break;
        }
      })
      .catch(() => {
        this.isLoading = false;
      });
    getShopProducts(this.$route.params.id, this.pagination, this.sort).then(
      (response) => {
        this.products = response.data;
        if (response.meta?.pagination?.pageCount === this.pagination.page) {
          this.pagination.isLastPage = true;
        }
      }
    );
    getShopProducts(
      this.$route.params.id,
      {
        page: null,
        pageSize: 500,
      },
      this.sort
    ).then((response) => {
      this.allProducts = response.data;
    });
  },
  methods: {
    getSortObj(data) {
      this.sort = data;
    },
    nextPage() {
      this.isLoading = true;
      this.pagination.page++;
      getShopProducts(this.$route.params.id, this.pagination, this.sort).then(
        (response) => {
          this.isLoading = false;
          this.products = [...this.products, ...response.data];
          if (response.meta?.pagination?.pageCount === this.pagination.page) {
            this.pagination.isLastPage = true;
          }
        }
      );
    },
    getMarkerIcon(shop) {
      const iconUrl =
        shop.attributes?.shop_type?.data?.attributes?.logo?.data?.attributes
          ?.url;
      return {
        layout: "default#image",
        imageHref: iconUrl
          ? process.env.VUE_APP_API_URL + iconUrl
          : MAP_MARKER_HYPER,
        imageSize: [60, 60],
        imageOffset: [-10, -5],
      };
    },
    getShopWorkTime(shop) {
      const timeOptions = { hour: "2-digit", minute: "2-digit" };
      const openTime = new Date(
        "December 14, 2026 " + shop.attributes.open_hour
      ).toLocaleString("ru-RU", timeOptions);
      const closeTime = new Date(
        "December 14, 2026 " + shop.attributes.close_hour
      ).toLocaleString("ru-RU", timeOptions);
      if (openTime === "00:00" && closeTime === "00:00") {
        return "aroundClock";
      }
      return `${openTime} - ${closeTime}`;
    },
    getImage(item) {
      if (item?.attributes?.logo?.data?.attributes?.url) {
        return this.$helpers.getAbsolutePath(
          item?.attributes?.logo?.data?.attributes?.url
        );
      } else {
        return require("@/" + "assets/img/icons/check-icon.svg");
      }
    },
    imageUrl(feature) {
      if (feature.attributes.logo.data.attributes.url) {
        return this.$helpers.getAbsolutePath(
          feature.attributes.logo.data.attributes.url
        );
      }
      return require("@/" + "assets/img/icons/placeholder.svg");
    },
  },
};
</script>

<style scoped>
.ymap-container {
  height: 625px;
}
</style>
